<template>
    <div class="comments-block hide-print-css">
        <div class="comments-block__header">
            <div class="comments-block__title">{{commentsList.count}} {{ functions.num2str(commentsList.count, phrases.comments) }}</div>
        </div>
        <comments
            :comments="commentsList"
            :obj-id="objId"
            :update-comments-count="updateCommentsCount"
            :content-type="contentType"
        />
    </div>
</template>

<script>
import Comments from '@/components/wall/Comments/Comments';
import functions from "@/assets/js/functions";
export default {
    name: 'CommentsBlock',
    components: {
        Comments
    },
    data () {
        return {
            phrases: {
              comments: ['комментарий', 'комментария', 'комментариев']
            },
            functions
        }
    },
    props: {
        commentsList: {
            type: Object,
            required: false,
            default: []
        },
        objId: {
            type: [Number, String],
            required: true
        },
        updateCommentsCount: {
            type: Function,
            required: true
        },
        contentType: {
            type: String
        }
    },
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.comments-block {
    &__title {
        color: $black;
        font-weight: 600;
        font-size: rem(20px);
    }
}
</style>
